.wrapper {
  display: grid;
  grid-template-columns: 2fr 1fr;
}

.body {
  padding-right: 4rem;
}

.ads {
  background-color: black;
}

.upgrade {
  height: 40px;
  width: 100%;
  position: absolute;
  bottom: 0;
  padding-bottom: 8rem;
}

.card {
  display: flex;
  border-style: solid;
  /* Add shadows to create the "card" effect */
  box-shadow: -4px 4px 0 0 rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  padding: 2rem;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 2rem;
  width: 45rem;
  height: 25rem;
}

.questionSection {
  width: 100%;
  position: relative;
  margin-right: 1rem;
  font-size: 1rem
}

.questionTextLarge {
  width: 100%;
  position: relative;
  font-size: 0.75rem
}

.questionCount {
  margin-bottom: 20px;
}

.questionCount span {
  font-size: 28px;
}

.questionText {
  margin-bottom: 12px;
}

.answerSection {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.answerButton {
  width: 100%;
  font-size: 16px;
  color: #6b7280;
  background-color: #f3f4f6;
  border-radius: 15px;
  display: flex;
  padding: 5px;
  justify-content: flex-start;
  align-items: center;
  border: 5px solid #e5e7eb;
  cursor: pointer;
}

.answerButton:hover {
  background-color: #555e7d;
  color: #ffff;
}

.answerButton:focus {
  outline: none;
}

.correct {
  width: 100%;
  font-size: 16px;
  color: #fff;
  background-color: #2f922f;
  border-radius: 15px;
  display: flex;
  padding: 5px;
  justify-content: flex-start;
  align-items: center;
  border: 5px solid #e5e7eb;
  cursor: pointer;
}

.incorrect {
  width: 100%;
  font-size: 16px;
  color: #fff;
  background-color: #ff3333;
  border-radius: 15px;
  display: flex;
  padding: 5px;
  justify-content: flex-start;
  align-items: center;
  border: 5px solid #e5e7eb;
  cursor: pointer;
}

.control {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
}

.divSpace {
  padding-bottom: 3rem;
}

.options {
  display: flex;
  justify-content: space-between;
}

.modal {
  display: block;
  position: absolute;
  left: 10%;
  top: 10%;
  padding: 1.5rem;
  transform: translate(-50%, -50%);
  align-items: center;
  min-width: 15rem;
  background: white;
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
  border-radius: 0.2rem;
  transform: translateX(-10px);
  opacity: 1;
  z-index: 100;
}

.modal p {
  padding: 2rem;
}

.modal div {
  display: flex;
  justify-content: space-between;
}
