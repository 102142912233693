.calendar {
  display: flex;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  align-items: center;
  min-width: 24rem;
  z-index: 100;
}

.form {
  background-color: #ffffff;
  width: 100%;
  border-radius: 0.5rem;
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
}

.header {
  display: flex;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  background-color: #f3f4f6;
  justify-content: right;
  align-items: center;
}

.materialIcon {
  color: #9ca3af;
}

.materialButton {
  cursor: pointer;
  background: transparent;
  border: none;
}

.body {
  padding: 0.75rem;
  row-gap: 7px;
}

.inputText {
  padding: 0.5rem;
  padding-right: 0;
  margin-bottom: 0.5rem;
}

.inputText:focus {
  border-color: #3b82f6;
  box-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width))
    var(--tw-ring-color);
  outline: 0;
}

.formFooter {
  display: flex;
  padding: 0.75rem;
  margin-top: 1.25rem;
  justify-content: flex-end;
  border-top-width: 1px;
}

.roundIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 9999px;
  cursor: pointer;
}

.materialCheck {
  color: white;
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.bookmark {
  display: flex;
  column-gap: 0.5rem;
  padding-top: 1.5rem;
}
