.list {
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
}

.card {
  border-style: none;
  /* Add shadows to create the "card" effect */
  box-shadow: 0px 5px 15px 3px rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-radius: 5px;
  padding: 0.5rem;
  margin: 1rem;
  width: 28rem;
  height: 12rem;
  overflow-y: scroll;
}

.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.cardItem {
  cursor: pointer;
  padding: 0.3rem;
}

.cardItem:hover {
  background-color: #eee;
  border-radius: 10px;
}

.item {
  width: 25%;
  padding: 5px;
  box-sizing: border-box;
}

.tagsContainer {
  padding-top: 1rem;
  align-items: center;
  overflow-x: scroll;
}

.tagItem {
  display: inline;
  margin-left: 0.6rem;
}

.deleteModal {
  display: block;
  position: absolute;
  left: 20%;
  top: 10%;
  max-width: 40%;
  padding: 1.5rem;
  transform: translate(-50%, -50%);
  align-items: center;
  min-width: 15rem;
  background: white;
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
  border-radius: 0.2rem;
  transform: translateX(-10px);
  opacity: 1;
  z-index: 100;
}

.deleteModal p {
  padding: 2rem;
}

.deleteModal div {
  display: flex;
  justify-content: space-between;
}
