.month {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(8, minmax(40px, 1fr));
  grid-template-rows: 40px;
  grid-auto-rows: minmax(50px, auto);
  overflow: auto;
}

.cell_title {
  font-size: 12px;
  text-transform: uppercase;
  color: #99a1a7;
  text-align: center;
  border: 1px solid #e1e1e1;
  line-height: 50px;
  font-weight: 500;
}

.day_name {
  font-size: 12px;
  color: #99a1a7;
  text-align: center;
  border: 1px solid #e1e1e1;
  line-height: 50px;
  font-weight: 500;
  cursor: pointer;
}

.currentDay {
  padding: 0.25rem;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  text-align: right;
  color: #6b7280;
  background-color: #2563eb;
  color: #ffffff;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  margin-left: auto;
  margin-right: auto;
}

.eventsModal {
  display: flex;
  position: absolute;
  left: 40%;
  top: 30%;
  transform: translate(-50%, -50%);
  align-items: center;
  min-width: 24rem;
  z-index: 100;
}

.form {
  background-color: #ffffff;
  width: 100%;
  border-radius: 0.5rem;
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
}

.header {
  display: flex;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  background-color: #f3f4f6;
  justify-content: right;
  align-items: center;
}

.body {
  margin: 1rem;
}

.eventsModal button {
  margin: 0.5rem;
  background-color: white;
  cursor: pointer;
}

.eventsModal button:hover {
  background-color: #ccc;
}

.footer {
  display: flex;
  padding: 0.75rem;
  margin-top: 1.25rem;
  justify-content: flex-end;
  border-top-width: 1px;
}

.materialIconDelete {
  color: #9ca3af;
  padding-right: 0.3rem;
  cursor: pointer;
}

.materialButton {
  cursor: pointer;
  background: transparent;
  border: none;
}

.task {
  color: #4b5563;
  line-height: 1.75rem;
  width: 80%;
  border-width: 0;
  border-bottom-width: 2px;
  border-color: #e5e7eb;
  background-color: white;
  margin-top: 2rem;
}

.task:hover {
  background-color: #f9fafb;
}

.task:focus {
  border-color: #3b82f6;
  background-color: #f9fafb;
  box-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width))
    var(--tw-ring-color);
  outline: 0;
}