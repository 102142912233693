h5 {
  margin: 0;
  margin-bottom: 0.3rem;
}

.tagsContainer {
  padding-top: 1rem;
  align-items: center;
  overflow-x: scroll;
}

.list {
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
}

.item {
  width: 25%;
  padding: 5px;
  box-sizing: border-box;
}

.card {
  border-style: solid;
  transition: 0.3s;
  border-radius: 5px;
  padding: 1.6rem;
  margin: 2rem;
  width: 10rem;
  height: 8rem;
  cursor: pointer;
}

.card div {
  padding-top: 2rem;
  text-align: center;
}

.info {
  display: flex;
  align-items: center;
}

/* On mouse-over, add a deeper shadow */
.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.cardIcon {
  font-size: 0.9rem;
  padding-right: 16px;
  color: blue;
}

.tagsContainer {
  display: flex;
  align-items: center;
}

.tag {
  background-color: white;
  border-style: solid;
  border-color: #e1e1e1;
  display: flex;
  font-size: 0.7rem;
  margin: 0.2rem;
  padding: 0.3rem 0.55rem;
  border-radius: 20px;
  cursor: pointer;
  transition-duration: 0.4s;
  text-decoration: none;
  overflow: hidden;
  position: relative;
  color: #6b7280;
}

.cardIcon {
  font-size: 0.9rem;
}

.tagsContainer {
  padding-top: 1rem;
  align-items: center;
  overflow-x: scroll;
}

.tagItem {
  display: inline;
  margin-left: 0.6rem;
}