.container {
  background-color: blanchedalmond;
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  align-items: center;
  text-align: center;
  overflow-y: scroll;
  object-fit: contain;
  /* box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23); */
}

.content {
  background-color: #eee;
}

.title{
  padding: 10rem;
  color: #555;
}

.card {
  background-color: white;
  border-style: none;
  /* Add shadows to create the "card" effect */
  box-shadow: 0px 5px 15px 3px rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-radius: 12px;
  padding: 0.5rem;
  margin: 3rem;
  width: 58rem;
  min-height: 28rem;
}

.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.cardItem {
  padding: 0.7rem;
}

.img {
  padding-top: 4rem;
  margin-left: auto;
  margin-right: auto;
}

.footer {
  color: #555;
  height: 100vh;
  width: 100%;
  /* background-color: #242424; */
  padding: 4rem 0 8rem 0;
  display: flex;
  justify-content: center;
  align-self: center;
}