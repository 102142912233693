.alphabet {
  text-transform: uppercase;
  text-align: center;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.alphabet span {
  margin: 0.5rem;
  cursor: pointer;
}

.alphabet span:hover {
  font-weight: 600;
}

.letter {
  text-transform: uppercase; 
  font-weight: 600;
  text-align: center;
  margin-bottom: 2rem;
}

.letterItems {
  margin-bottom: 0.5rem;
}

.letterItems:hover {
  font-weight: 500;
}