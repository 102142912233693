.currentMonth {
  color: #6b7280;
  font-size: 1rem;
  line-height: 1.75rem;
  font-weight: 700;
}

.controlMonth {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  display: flex;
  align-items: center;
}

.settings {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  display: flex;
  align-items: center;
  margin-left: auto;
}

.button {
  text-transform: capitalize;
  font-size: 0.9rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-right: 0.5rem;
  margin-left: 0.5rem;
  border-radius: 0.25rem;
  border: 1px solid #e1e1e1;
  background: white;
  cursor: pointer;
  max-width: 90px;
  max-height: 40px;
  display: inline-flex;
  transition-duration: 0.4s;
  text-decoration: none;
  overflow: hidden;
  position: relative;
  color: #6b7280;
}

.myCalendars {
  padding-right: 5rem;
}

.button p {
  padding-right: 0.3rem;
}

.button:hover {
  outline: none;
  background: #eee;
}

.button:after {
  content: "";
  background: white;
  display: block;
  position: absolute;
  padding-top: 300%;
  padding-left: 350%;
  margin-left: -20px !important;
  margin-top: -120%;
  opacity: 0;
  transition: all 1s;
}

.button:active:after {
  padding: 0;
  margin: 0;
  opacity: 1;
  transition: 0s;
}

.toggleBar {
  display: inline-flex;
}

.navFilterModal {
  background: white;
  border-radius: 0.2rem;
  position: absolute;
  top: 100%;
  left: 25%;
  width: 8rem;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
  opacity: 1;
  transform: translateX(-20px);
  transition: opacity 0.4s ease, transform 0.4s ease, visibility 0.4s;
  z-index: 100;
  overflow-y: scroll;
  max-height: 15rem;
  padding-inline: 1rem;
  padding-bottom: 1rem;
}

.menuContainer {
  position: relative;
}

.menuInactive {
  background: #ffffff;
  border-radius: 8px;
  position: absolute;
  top: 60px;
  right: 0;
  width: 300px;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
  opacity: 0;
  visibility: hidden;
  transform: translateY(-20px);
  transition: opacity 0.4s ease, transform 0.4s ease, visibility 0.4s;
}

.menuActive {
  background: #ffffff;
  border-radius: 8px;
  position: absolute;
  top: 60px;
  right: 0;
  width: 300px;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
  transform: translateY(-20px);
  transition: opacity 0.4s ease, transform 0.4s ease, visibility 0.4s;
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

.menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.menu li {
  border-bottom: 1px solid #dddddd;
}

.menu li a {
  text-decoration: none;
  color: #333333;
  padding: 15px 20px;
  display: block;
}

.menuTrigger {
  background: #ffffff;
  border-radius: 90px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 6px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  border: none;
  vertical-align: middle;
  transition: box-shadow 0.4s ease;
}

.menuTrigger:hover {
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
}

.menuTrigger span {
  font-weight: 700;
  vertical-align: middle;
  font-size: 14px;
  margin: 0 10px;
}

.menuTrigger img {
  border-radius: 90px;
}
