.container {
  position: absolute;
  right: 0;
  top: 2;
  width: 200px;
  height: 39rem;
  bottom: 0;
  padding: 10px;
  box-sizing: border-box;
  z-index: 10;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.input {
  border: none;
  width: 100%;
  box-sizing: border-box;
  border-bottom: solid 1px #1b5e20;
}

.textarea {
  border: none;
  width: 100%;
  box-sizing: border-box;
  border-bottom: solid 1px #1b5e20;
  max-height: 6rem;
}

fieldset {
  border: none;
  padding: 0;
  margin: 2px 0;
}

label {
  font-size: 10px;
  opacity: 0.5;
}

.roundIcon {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 1rem;
  height: 1rem;
  border-radius: 9999px;
  cursor: pointer;
  margin-right: 0.5rem;
}

.materialCheck {
  color: white;
  font-size: 0.875rem;
  line-height: 1.25rem;
}

/* TAGS */

.tagsContainer {
  display: flex;
  flex-direction: column;
}

.tag {
  background-color: white;
  border-style: solid;
  border-color: #e1e1e1;
  display: flex;
  margin: 0.3rem;
  margin-right: auto;
  padding: 0.3rem 0.3rem;
  border-radius: 20px;
  cursor: pointer;
  transition-duration: 0.4s;
  text-decoration: none;
  overflow: hidden;
  position: relative;
  color: #6b7280;
  font-size: 0.6rem;
}

.tag:hover {
  outline: none;
  background: #eee;
}

.tag:after {
  content: "";
  background: white;
  display: block;
  position: absolute;
  padding-top: 300%;
  padding-left: 350%;
  margin-left: -20px !important;
  margin-top: -120%;
  opacity: 0;
  transition: all 1s;
}

.tag:active:after {
  padding: 0;
  margin: 0;
  opacity: 1;
  transition: 0s;
}

.tagClose {
  height: 10px;
  width: 10px;
  background-color: rgb(48, 48, 48);
  color: #fff;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-left: 0.5rem;
  font-size: 10px;
}

.modalFooter {
  display: flex;
  padding: 0.75rem;
  margin-top: 1.25rem;
  justify-content: flex-end;
  border-top-width: 1px;
}

.labelBlack {
  color: black;
}

.save {
  cursor: pointer;
  color: #3b82f6;
}

.choose {
  cursor: pointer;
  color: black;
}

button {
  display: inline;
}
