.wrapper {
  display: grid;
  grid-template-columns: 2fr 1fr;
}

.body {
  padding-right: 4rem;
}

.ads {
  background-color: black;
}

.upgrade {
  height: 40px;
  width: 100%;
  position: absolute;
  bottom: 0;
  padding-bottom: 8rem;
}

.card {
  display: flex;
  justify-content: center;
  align-items: center;
  border-style: solid;
  /* Add shadows to create the "card" effect */
  box-shadow: -4px 4px 0 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-radius: 5px;
  padding: 2rem;
  margin-left: auto;
  margin-right: auto;
  margin-top: 2rem;
  margin-bottom: 2rem;
  width: 20rem;
  height: 8rem;
  cursor: pointer;
  transform-style: preserve-3d;
  transition: 150ms;
  transform: perspective(1000px) rotateY(var(--rotate-y, 0));
}

/* On mouse-over, add a deeper shadow */
.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.flip {
  --rotate-y: 180deg;
}

.slide {
  animation-name: slide;
}

.card .front,
.card .back {
  position: absolute;
  backface-visibility: hidden;
  padding: 1rem;
}

.card .back {
  transform: rotateY(180deg);
}

.control {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
}

.options {
  display: flex;
  justify-content: space-between;
}

.deleteModal {
  display: block;
  position: absolute;
  left: 40%;
  top: 30%;
  padding: 1.5rem;
  transform: translate(-50%, -50%);
  align-items: center;
  min-width: 15rem;
  background: white;
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
  border-radius: 0.2rem;
  transform: translateX(-10px);
  opacity: 1;
  z-index: 100;
}

.deleteModal p {
  padding: 2rem;
}

.deleteModal div {
  display: flex;
  justify-content: space-between;
}
