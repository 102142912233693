.container {
  fill: white;
  fill-opacity: 0.9;
  stroke: none;
  paint-order: stroke;
  cursor: pointer;
}

.background {
  border: none;
  fill: white;
  fill-opacity: 0.9;
  paint-order: stroke;
  cursor: pointer;
}

.text {
  font-size: 2rem;
  fill: black;
  cursor: pointer;
}

.left {
  text-anchor: end;
}

.middle {
  text-anchor: middle;
}

.right {
  text-anchor: start;
}

.selected {
  stroke-width: 2px;
  stroke: blue;
  /* animation: pulse infinite 1s linear; */
}

.icon {
  font-size: 3rem;
}
