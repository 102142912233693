.body {
  max-height: 10rem;
  overflow-y: scroll;
}

.body button {
  padding-bottom: 0.7em;
  padding-top: 0.8rem;
  padding-right: 3rem;
  padding-left: 0.8rem;
  color: #6b7280;
  font-size: 14px;
  cursor: pointer;
  background: transparent;
  border: none;
}

.body button:hover {
  background-color: #eee;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(107, 114, 128, 0.5);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}
