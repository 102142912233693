.list {
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
}

.card {
  border-style: solid;
  box-shadow: -4px 4px 0 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-radius: 5px;
  padding: 1.6rem;
  margin: 2rem;
  width: 20rem;
  height: 8rem;
  cursor: pointer;
}

.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.cardSelected {
  background: #0097a722;
  color: #6b7280;
  border-style: solid;
  box-shadow: -4px 4px 0 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-radius: 5px;
  padding: 1.6rem;
  margin: 2rem;
  width: 20rem;
  height: 8rem;
  cursor: pointer;
}

.cardSelected:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.infoContainer {
  display: flex;
  align-items: center;
}

.tagsBar {
  padding-top: 1rem;
  align-items: center;
  overflow-x: scroll;
}

.tagItem {
  display: inline;
  margin-left: 0.6rem;
}

.tagsContainer {
  display: flex;
  align-items: center;
}

.tag {
  background-color: white;
  border-style: solid;
  border-color: #e1e1e1;
  display: flex;
  font-size: 0.7rem;
  margin: 0.2rem;
  padding: 0.3rem 0.55rem;
  border-radius: 20px;
  cursor: pointer;
  transition-duration: 0.4s;
  text-decoration: none;
  overflow: hidden;
  position: relative;
  color: #6b7280;
}

.cardIcon {
  font-size: 0.9rem;
}

.user {
  margin-top: 4rem;
}

.deleteModal {
  display: block;
  position: absolute;
  left: 20%;
  top: 10%;
  max-width: 40%;
  padding: 1.5rem;
  transform: translate(-50%, -50%);
  align-items: center;
  min-width: 15rem;
  background: white;
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
  border-radius: 0.2rem;
  transform: translateX(-10px);
  opacity: 1;
  z-index: 100;
}

.deleteModal p {
  padding: 2rem;
}

.deleteModal div {
  display: flex;
  justify-content: space-between;
}