.container {
  background-color: white;
  border-style: solid;
  transition: 0.3s;
  border-radius: 5px;
  padding: 1.6rem;
  margin: 2rem;
  width: 5rem;
  height: 4rem;
  cursor: pointer;
  text-align: center;
}

.container:hover {
  /* box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23); */
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.selected {
  background: #0097a722;
  color: #00bcd4ff;
}

.title {
  margin: 0;
  margin-bottom: 0.3rem;
  font-size: 1rem;
}

.cardIcon {
  margin-left: auto;
  margin-right: auto;
  font-size: 2rem;
}
