.container {
  display: flex;
  position: absolute;
  height: 95%;
  width: 80%;
  bottom: 0;
  z-index: 0;
  background-color: #f7f7f7;
}

/* ICONS */

.iconsModal {
  display: block;
  position: absolute;
  left: 40%;
  top: 30%;
  padding: 1.5rem;
  transform: translate(-50%, -50%);
  align-items: center;
  min-width: 15rem;
  max-width: 20rem;
  min-height: 15rem;
  max-height: 15rem;
  overflow-y: scroll;
  background: white;
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
  border-radius: 0.2rem;
  transform: translateX(-10px);
  opacity: 1;
  z-index: 100;
}

/* TAGS */

.tagsModal {
  display: block;
  position: absolute;
  left: 25%;
  top: 10%;
  padding: 1.5rem;
  transform: translate(-50%, -50%);
  align-items: center;
  min-width: 25rem;
  max-width: 30rem;
  min-height: 30rem;
  max-height: 30rem;
  overflow-y: scroll;
  background: white;
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
  border-radius: 0.2rem;
  transform: translateX(-10px);
  opacity: 1;
  z-index: 100;
}

.tagsModal h3 {
  text-align: center;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
}

.tagsModal p {
  padding: 2rem;
}

.tagsModal div {
  display: flex;
  justify-content: space-between;
}

.tagsContainer {
  display: flex;
  align-items: center;
}

.prev {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding-right: 0.5rem;
}

.prev:hover {
  background-color: #eee;
  border-radius: 10px;
}

.next {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding-left: 0.5rem;
}

.next:hover {
  background-color: #eee;
  border-radius: 10px;
}

.more {
  color: black;
  padding-top: 0.7rem;
  padding-left: 0.7rem;
  padding-right: 0.7rem;
  cursor: pointer;
}

.more:hover {
  background-color: #eee;
  border-radius: 10px;
}

.tag {
  background-color: white;
  border-style: solid;
  border-color: #e1e1e1;
  display: flex;
  margin: 0.5rem;
  padding: 0.8rem 0.75rem;
  align-items: center;
  border-radius: 20px;
  cursor: pointer;
  transition-duration: 0.4s;
  text-decoration: none;
  overflow: hidden;
  position: relative;
  color: #6b7280;
}

.tag:hover {
  outline: none;
  background: #eee;
}

.tag:after {
  content: "";
  background: white;
  display: block;
  position: absolute;
  padding-top: 300%;
  padding-left: 350%;
  margin-left: -20px !important;
  margin-top: -120%;
  opacity: 0;
  transition: all 1s;
}

.tag:active:after {
  padding: 0;
  margin: 0;
  opacity: 1;
  transition: 0s;
}

.tagActive {
  background-color: #6b7280;
  border-style: solid;
  border-color: #e1e1e1;
  display: flex;
  margin: 0.5rem;
  padding: 0.8rem 0.75rem;
  align-items: center;
  border-radius: 20px;
  cursor: pointer;
  transition-duration: 0.4s;
  text-decoration: none;
  overflow: hidden;
  position: relative;
  color: white;
}

.tagClose {
  height: 20px;
  width: 20px;
  background-color: rgb(48, 48, 48);
  color: #fff;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-left: 0.5rem;
  font-size: 18px;
}

.modalFooter {
  display: flex;
  padding: 0.75rem;
  margin-top: 1.25rem;
  justify-content: flex-end;
  border-top-width: 1px;
}

.choose {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: scroll;
  min-width: 10rem;
  min-height: 10rem;
  max-height: 10rem;
  border: 1px;
  border-style: solid;
}

/* CARDS */

.card {
  display: flex;
  width: 60%;
  flex-direction: column;
  background-color: white;
  border-style: solid;
  border-color: #e1e1e1;
  margin: 0.3rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  padding-top: 0.5rem;
  padding-bottom: 5rem;
  align-items: center;
  border-radius: 5px;
  cursor: pointer;
  transition-duration: 0.4s;
  text-decoration: none;
  overflow: hidden;
  position: relative;
  color: #6b7280;
}

.card:hover {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}

.cardActive {
  display: flex;
  width: 60%;
  flex-direction: column;
  background-color: #6b7280;
  border-style: solid;
  border-color: #6b7280;
  margin: 0.3rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  padding-top: 0.5rem;
  padding-bottom: 5rem;
  align-items: center;
  border-radius: 5px;
  cursor: pointer;
  transition-duration: 0.4s;
  text-decoration: none;
  overflow: hidden;
  position: relative;
  color: white;
}

.cardActive:hover {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}

.cardTag {
  background-color: white;
  border-style: solid;
  border-color: #e1e1e1;
  display: flex;
  font-size: 0.5rem;
  align-items: center;
  margin: 0.2rem;
  padding: 0.3rem 0.55rem;
  border-radius: 20px;
  cursor: pointer;
  transition-duration: 0.4s;
  text-decoration: none;
  overflow: hidden;
  position: relative;
  color: #6b7280;
}
