.content {
  /* display: grid;
  grid-template-columns: 1fr 1fr; */
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
}

.hexCard {
  float: left;
  cursor: pointer;
  margin-top: 5rem;
  margin-left: 5rem;
  width: 60px;
  height: 104px;
  background-color: #6b7280;
  border-color: #6b7280;
  /* background-color: #00bcd4ff;
  border-color: #00bcd4ff; */
  position: relative;
  transition: all 0.2s ease-in;
}
.hexCard:before {
  float: left;
  content: " ";
  border-right: 30px solid;
  border-color: inherit;
  border-top: 52px solid transparent;
  border-bottom: 52px solid transparent;
  position: absolute;
  left: -30px;
}
.hexCard:after {
  float: left;
  content: "";
  top: 0;
  border-left: 30px solid;
  border-color: inherit;
  border-top: 52px solid transparent;
  border-bottom: 52px solid transparent;
  position: absolute;
  right: -30px;
}

/* .hexCard {
  cursor: pointer;
  margin-top: 5rem;
  width: 104px;
  height: 60px;
  background-color: #00bcd4ff;
  border-color: #00bcd4ff;
  position: relative;
  box-shadow: 0 0 5px rgba(0,0,0,0.6);
  transition: all 0.2s ease-in;
}
.hexCard:before {
  content: " ";
  width: 0; height: 0;
  border-bottom: 30px solid;
  border-color: inherit;
  border-left: 52px solid transparent;
  border-right: 52px solid transparent;
  position: absolute;
  top: -30px;
}
.hexCard:after {
  content: "";
  width: 0;
  position: absolute;
  bottom: -30px;
  border-top: 30px solid;
  border-color: inherit;
  border-left: 52px solid transparent;
  border-right: 52px solid transparent;
} */

.hexCard:hover {
  /* background-color: #00308F;
  border-color: #00308F; */
  transform: translateY(-5px);
}

.cardContent {
  color: #F0F8FF;
  font-size: 1rem;
  text-align: center;
  position: relative;
  z-index: 2;
}

.hexIcon {
  color: #F0F8FF;
  margin-top: 0.8rem;
  margin-left: 1rem;
  font-size: 1.4rem;
}