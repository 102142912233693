.cell {
  display: flex;
  flex-direction: column;
  border-style: solid;
  border-width: 1px;
  border-color: #e1e1e1;
  background-color: white;
  position: relative;
  overflow-y: auto;
  cursor: pointer;
  z-index: 1;
}

.cell:nth-of-type(7n + 7) {
  border-width: 1px;
}
.cell:nth-of-type(n + 1):nth-of-type(-n + 7) {
  grid-row: 2;
}
.cell:nth-of-type(n + 8):nth-of-type(-n + 14) {
  grid-row: 3;
}
.cell:nth-of-type(n + 15):nth-of-type(-n + 21) {
  grid-row: 4;
}
.cell:nth-of-type(n + 22):nth-of-type(-n + 28) {
  grid-row: 5;
}
.cell:nth-of-type(n + 29):nth-of-type(-n + 35) {
  grid-row: 6;
}
.cell:nth-of-type(n + 36):nth-of-type(-n + 42) {
  grid-row: 7;
}
.cell:nth-of-type(7n + 1) {
  grid-column: 1/1;
}
.cell:nth-of-type(7n + 2) {
  grid-column: 2/2;
}
.cell:nth-of-type(7n + 3) {
  grid-column: 3/3;
}
.cell:nth-of-type(7n + 4) {
  grid-column: 4/4;
}
.cell:nth-of-type(7n + 5) {
  grid-column: 5/5;
}
.cell:nth-of-type(7n + 6) {
  grid-column: 6/6;
}
.cell:nth-of-type(7n + 7) {
  grid-column: 7/7;
}

.dayNumber {
  padding: 0.2rem;
  margin-top: 0.2rem;
  margin-bottom: 0.2rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  text-align: right;
  color: #6b7280;
}

.currentDay {
  padding: 0.25rem;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  text-align: right;
  color: #6b7280;
  background-color: #2563eb;
  color: #ffffff;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  right: 0;
  left: 0;
  margin-left: auto;
}

.body {
  flex: 1 1 0%;
  cursor: pointer;
}
