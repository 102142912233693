textarea {
  resize: none;
  border: none;
}

input {
  width: 100%;
  margin-bottom: 1rem;
  background-color: white;
}

.card {
  border-style: solid;
  /* Add shadows to create the "card" effect */
  box-shadow: -4px 4px 0 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-radius: 5px;
  padding: 1.6rem;
  margin: 2rem;
  width: 45rem;
  height: 21rem;
  text-align: center;
}

.head {
  display: block;
  margin-left: 20%;
  margin-right: 20%;
}

.footer {
  display: flex;
  justify-content: space-between;
}

.options {
  display: flex;
  justify-content: flex-end;
}

.options input {
  margin-left: 20%;
  margin-right: 10%;
}

.save {
  margin-left: auto;
}

/* TAGS */

.tagsModal {
  display: block;
  position: absolute;
  left: 40%;
  top: 30%;
  padding: 1.5rem;
  transform: translate(-50%, -50%);
  align-items: center;
  min-width: 15rem;
  min-height: 15rem;
  overflow-y: scroll;
  background: white;
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
  border-radius: 0.2rem;
  transform: translateX(-10px);
  opacity: 1;
  z-index: 100;
}

.tagsModal p {
  padding: 2rem;
}

.tagsModal div {
  display: flex;
  justify-content: space-between;
}

.tagsContainer {
  display: flex;
  align-items: center;
}

.prev {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding-right: 0.5rem;
}

.prev:hover {
  background-color: #eee;
  border-radius: 10px;
}

.next {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding-left: 0.5rem;
}

.next:hover {
  background-color: #eee;
  border-radius: 10px;
}

.more {
  color: black;
  padding-top: 0.7rem;
  padding-left: 0.7rem;
  padding-right: 0.7rem;
  cursor: pointer;
}

.more:hover {
  background-color: #eee;
  border-radius: 10px;
}

.tag {
  background-color: white;
  border-style: solid;
  border-color: #e1e1e1;
  display: flex;
  margin: 0.5rem;
  padding: 0.5rem 0.75rem;

  border-radius: 20px;
  cursor: pointer;
  transition-duration: 0.4s;
  text-decoration: none;
  overflow: hidden;
  position: relative;
  color: #6b7280;
}

.tag:hover {
  outline: none;
  background: #eee;
}

.tag:after {
  content: "";
  background: white;
  display: block;
  position: absolute;
  padding-top: 300%;
  padding-left: 350%;
  margin-left: -20px !important;
  margin-top: -120%;
  opacity: 0;
  transition: all 1s;
}

.tag:active:after {
  padding: 0;
  margin: 0;
  opacity: 1;
  transition: 0s;
}

.tagClose {
  height: 20px;
  width: 20px;
  background-color: rgb(48, 48, 48);
  color: #fff;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-left: 0.5rem;
  font-size: 18px;
}

.modalFooter {
  display: flex;
  padding: 0.75rem;
  margin-top: 1.25rem;
  justify-content: flex-end;
  border-top-width: 1px;
}
