.month {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(7, minmax(40px, 1fr));
  grid-template-rows: 40px;
  grid-auto-rows: minmax(120px, auto);
  overflow: auto;
}

.day_name {
  font-size: 12px;
  text-transform: uppercase;
  color: #99a1a7;
  text-align: center;
  border: 1px solid #e1e1e1;
  line-height: 50px;
  font-weight: 500;
}

.event {
  position: relative;
  z-index: 2;
  padding-left: 0.3rem;
  height: 1.5rem;
  margin-top: 2rem;
  margin-bottom: 0.25rem;
  margin-right: 0.75rem;
  color: #4b5563;
  font-size: 0.75rem;
  line-height: 1.25rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-radius: 0.25rem;
  cursor: pointer;
}

.event:hover {
  filter: brightness(90%);
}

.eventOut {
  position: relative;
  z-index: 2;
  padding-left: 0.3rem;
  height: 1.5rem;
  margin-top: 2rem;
  margin-bottom: 0.25rem;
  color: #4b5563;
  font-size: 0.75rem;
  line-height: 1.25rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-radius: 0.25rem;
  cursor: pointer;
}

.eventOut:hover {
  filter: brightness(90%);
}

.eventOutMonth {
  position: relative;
  z-index: 2;
  padding-left: 0.3rem;
  height: 1.5rem;
  margin-top: 2rem;
  margin-bottom: 0.25rem;
  color: #4b5563;
  font-size: 0.75rem;
  line-height: 1.25rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-radius: 0.25rem;
  cursor: pointer;
}

.arrowRight {
  height: 0;
  width: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 10px solid green;
}

.eventOutMonth:hover {
  filter: brightness(90%);
}

.eventOne {
  margin-top: 2rem;
}
.eventTwo {
  margin-top: 3.8rem;
}

.eventMore {
  margin-top: 5.5rem;
  font-weight: bold;
  background-color: #f9fafb;
}

.eventsModal {
  display: flex;
  position: absolute;
  padding: 1.5rem;
  transform: translate(-50%, -50%);
  align-items: center;
  min-width: 10rem;
  background: white;
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
  border-radius: 0.2rem;
  transform: translateX(-10px);
  opacity: 1;
  z-index: 100;
}

.eventList {
  position: relative;
  z-index: 2;
  padding-left: 0.3rem;
  width: 10rem;
  height: 1.5rem;
  margin-top: 0.6rem;
  color: #4b5563;
  font-size: 0.75rem;
  line-height: 1.25rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-radius: 0.25rem;
  cursor: pointer;
}

.eventList:hover {
  filter: brightness(90%);
}

.eventModalDay {
  text-align: center;
  text-transform: uppercase;
  font-size: 0.8rem;
}

.eventModalDayNumber {
  text-align: center;
  color: black;
  font-size: 1.5rem;
}

.eventMenuContainer {
  position: relative;
}
