.event {
  display: flex;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  align-items: center;
  min-width: 24rem;
  z-index: 100;
}

.form {
  background-color: #ffffff;
  width: 100%;
  border-radius: 0.5rem;
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
}

.header {
  display: flex;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  background-color: #f3f4f6;
  justify-content: right;
  align-items: center;
}

.materialIcon {
  color: #9ca3af;
}

.materialIconDelete {
  color: #9ca3af;
  padding-right: 0.3rem;
  cursor: pointer;
}

.materialButton {
  cursor: pointer;
  background: transparent;
  border: none;
}

.materialCheck {
  color: white;
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.body {
  display: grid;
  grid-template-columns: 45px auto;
  padding: 0.75rem;
  align-items: flex-end;
  row-gap: 7px;
}

.title {
  padding-top: 0.75rem;
  color: #4b5563;
  font-size: 1.25rem;
  line-height: 1.75rem;
  font-weight: 600;
  width: 100%;
  border-width: 0;
  border-bottom-width: 2px;
  border-color: #e5e7eb;
  background-color: white;
}

.title:focus {
  border-color: #3b82f6;
  box-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width))
    var(--tw-ring-color);
  outline: 0;
}

.schedule {
  color: #4b5563;
  line-height: 1.75rem;
  border-width: 0;
  border-bottom-width: 2px;
  border-color: #e5e7eb;
  font-size: 14px;
}

.schedule input {
  display: inline;
}

.date {
  color: #4b5563;
  line-height: 1.75rem;
  border-width: 0;
  border-bottom-width: 2px;
  border-color: #e5e7eb;
  font-size: 14px;
  width: 60%;
  background-color: white;
}

.date:hover {
  background-color: #f9fafb;
}

.date:focus {
  border-color: #3b82f6;
  background-color: #f9fafb;
  box-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width))
    var(--tw-ring-color);
  outline: 0;
}

.time {
  color: #4b5563;
  line-height: 1.75rem;
  border-width: 0;
  border-bottom-width: 2px;
  border-color: #e5e7eb;
  font-size: 14px;
  width: 20%;
  background-color: white;
}

.time:hover {
  background-color: #f9fafb;
}

.time:focus {
  border-color: #3b82f6;
  background-color: #f9fafb;
  box-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width))
    var(--tw-ring-color);
  outline: 0;
}

/* The container */
.allDay {
  display: block;
  position: relative;
  padding-left: 25px;
  padding-top: 0.2rem;
  cursor: pointer;
  font-size: 12px;
  color: #4b5563;
  text-transform: capitalize;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-right: auto;
  padding-top: 0.5rem;
}

/* Hide the browser's default checkbox */
.allDay input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 19px;
  width: 19px;
  background-color: #eee;
  border-radius: 0.25rem;
  margin-top: 0.3rem;
}

/* On mouse-over, add a grey background color */
.allDay:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.allDay input:checked ~ .checkmark {
  background-color: #2196f3;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.allDay input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.allDay .checkmark:after {
  left: 6px;
  top: 2px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.description {
  color: #4b5563;
  line-height: 1.75rem;
  width: 100%;
  border-width: 0;
  border-bottom-width: 2px;
  border-color: #e5e7eb;
  background-color: white;
}

.description:hover {
  background-color: #f9fafb;
}

.description:focus {
  border-color: #3b82f6;
  background-color: #f9fafb;
  box-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width))
    var(--tw-ring-color);
  outline: 0;
}

.bookmark {
  display: flex;
  column-gap: 0.5rem;
  padding-top: 1.5rem;
}

.roundIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 9999px;
  cursor: pointer;
}

.yellow {
  display: flex;
  background-color: #f59e0b;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 9999px;
  cursor: pointer;
}

.gray {
  display: flex;
  background-color: #6b7280;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 9999px;
  cursor: pointer;
}

.green {
  display: flex;
  background-color: #10b981;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 9999px;
  cursor: pointer;
}

.blue {
  display: flex;
  background-color: #3b82f6;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 9999px;
  cursor: pointer;
}

.red {
  display: flex;
  background-color: #ef4444;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 9999px;
  cursor: pointer;
}

.footer {
  display: flex;
  padding: 0.75rem;
  margin-top: 1.25rem;
  justify-content: flex-end;
  border-top-width: 1px;
}

.smallCalendarStart {
  transition: 0.3s;
  transform: initial;
  display: block;
  position: absolute;
  top: 32.3%;
  left: 23.8%;
  width: 12.5rem;
  padding-inline: 0.5rem;
  padding-top: 0.3rem;
  background: white;
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
  border-radius: 0.2rem;
  transform: translateX(-10px);
  opacity: 1;
  z-index: 100;
}

.smallCalendarEnd {
  transition: 0.3s;
  transform: initial;
  display: block;
  position: absolute;
  top: 40%;
  left: 23%;
  width: 12.5rem;
  padding-inline: 0.5rem;
  padding-top: 0.3rem;
  background: white;
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
  border-radius: 0.2rem;
  transform: translateX(-10px);
  opacity: 1;
  z-index: 100;
}

.timeModalStart {
  transition: 0.3s;
  transform: initial;
  display: block;
  position: absolute;
  top: 32.3%;
  left: 63%;
  width: auto;
  background: white;
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
  border-radius: 0.2rem;
  transform: translateX(-10px);
  opacity: 1;
  z-index: 100;
}

.timeModalEnd {
  transition: 0.3s;
  transform: initial;
  display: block;
  position: absolute;
  top: 40%;
  left: 62%;
  width: auto;
  background: white;
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
  border-radius: 0.2rem;
  transform: translateX(-10px);
  opacity: 1;
  z-index: 100;
}

.repeatButton {
  display: inline-flex;
  cursor: pointer;
  margin-right: auto;
  padding-top: 0.5rem;
}

.repeatButton:hover {
  background-color: #eee;
  transition: 0.5s;
}

.repeatButton button {
  cursor: pointer;
  background: transparent;
  border: none;
  text-align: left;
  font-size: 14px;
  color: #4b5563;
}

.materialIconRepeat {
  color: #9ca3af;
}

.repeatModal {
  transition: 0.3s;
  transform: initial;
  display: block;
  position: absolute;
  top: 52%;
  left: 17%;
  width: auto;
  background: white;
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
  border-radius: 0.2rem;
  transform: translateX(-10px);
  opacity: 1;
  z-index: 100;
}

.calendarModal {
  transition: 0.3s;
  transform: initial;
  display: block;
  position: absolute;
  top: 69%;
  left: 15%;
  width: auto;
  background: white;
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
  border-radius: 0.2rem;
  transform: translateX(-10px);
  opacity: 1;
  z-index: 100;
}

.calendar {
  display: inline-flex;
  margin-right: auto;
}

.deleteModal {
  display: block;
  position: absolute;
  left: 40%;
  top: 30%;
  padding: 1.5rem;
  transform: translate(-50%, -50%);
  align-items: center;
  min-width: 15rem;
  background: white;
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
  border-radius: 0.2rem;
  transform: translateX(-10px);
  opacity: 1;
  z-index: 100;
}

.deleteModal h3 {
  font-weight: bold;
  font-size: 0.9rem;
  padding-bottom: 1.5rem;
}
