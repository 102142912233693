.container {
  background-color: white;
  border-style: solid;
  /* box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23); */
  box-shadow: -4px 4px 0 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-radius: 5px;
  padding: 1.6rem;
  margin: 2rem;
  width: 20rem;
  height: 8rem;
  cursor: pointer;
}

.container:hover {
  /* box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23); */
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.selected {
  background: #0097a722;
  color: #6b7280;
}

h5 {
  margin: 0;
  margin-bottom: 0.3rem;
}

.comment {
  font-size: 10px;
  opacity: 0.5;
}

.infoContainer {
  display: flex;
  align-items: center;
}

.tagsContainer {
  display: flex;
  align-items: center;
}

.tag {
  background-color: white;
  border-style: solid;
  border-color: #e1e1e1;
  display: flex;
  font-size: 0.7rem;
  margin: 0.2rem;
  padding: 0.3rem 0.55rem;
  border-radius: 20px;
  cursor: pointer;
  transition-duration: 0.4s;
  text-decoration: none;
  overflow: hidden;
  position: relative;
  color: #6b7280;
}

.cardIcon {
  font-size: 0.9rem;
}
