.body {
  max-height: 10rem;
  overflow-y: scroll;
}

.body button {
  padding-bottom: 0.7em;
  padding-top: 0.8rem;
  padding-right: 3rem;
  padding-left: 0.8rem;
  color: #6b7280;
  font-size: 13px;
  cursor: pointer;
  background: transparent;
  border: none;
  width: 100%;
  text-align: left;
}

.body button:hover {
  background-color: #eee;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(107, 114, 128, 0.5);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.roundIcon {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 1rem;
  height: 1rem;
  border-radius: 9999px;
  cursor: pointer;
  margin-right: 0.5rem;
}
